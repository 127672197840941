.college-search {
  width: 100%;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 5px;
  align-items: flex-start; 
}
  
  .college-search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .college-suggestions {
    list-style: none;
    padding: 0;
  }
  
  .college-suggestion-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
  
  .add-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  
  