.prompts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  padding-top: 5px;
  box-sizing: border-box; 
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.space-x-2 > :not(:last-child) {
  margin-right: 0.5rem;
}

.justify-center {
  justify-content: center;
}

.rounded-button {
  border-radius: 70px; /* Adjust this value to change the roundness of the corners */
}



/* Media query for mobile screens */
@media (max-width: 768px) {
  .prompts-container .inline-flex {
    font-size: 9px; /* Smaller font size on mobile */
    padding: 4px 8px; /* Even more compact padding */
    min-width: 60px; /* Further reduce width */
    height: 30px; /* Smaller height */
    border-radius: 35px; /* Keep rounded corners */
  }
}