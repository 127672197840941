@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
}

body.modal-open .modal-overlay {
  display: flex !important; /* Ensure the modal stays visible */
}


.row {
  padding: 10px;
  display: flex;
  justify-content: center; /* Center the content */
  max-width: 1200px; /* Restrict maximum width */
  margin: 0 auto; /* Center the entire .row within its container */
  box-sizing: border-box; /* Include padding in width calculations */
}

  
  .column-right {
    flex: 70%;;


  }
  
  .column-left {
    flex: 30%;
    background-color: #fcfeff;
    padding-left: 0px;
    
    
  }

.css-16ld5u0 {
    background-color: #fcfeff; 
    -webkit-margin-start: auto;
    margin-inline-start: auto;
    -webkit-margin-end: auto;
    margin-inline-end: auto;
    padding: 20px;
    -webkit-padding-end: 20px;
    padding-inline-end: 20px;
    min-height: 100vh;
    width: 900px;
}


.css-1k6m9o {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding-top: 45px;
    position: relative;
}

.css-1799jpi {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-margin-start: auto;
    margin-inline-start: auto;
    -webkit-margin-end: auto;
    margin-inline-end: auto;
    padding-right: 20px;
    width: 100%;
    max-width: 1000px;

}


.css-6n9yju {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
    margin-top: 0px;
    justify-self: flex-end;
}



.css-1pgcnou {
    width: 100%;
    min-width: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    font-weight: 500;
    border-radius: 45px;
    font-size: var(--chakra-fontSizes-sm);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    height: 100%;
    border: 1px solid;
    border-color: var(--chakra-colors-gray-200);
    background: inherit;
    min-height: 54px;
    padding: 15px 20px;
    -webkit-margin-end: 10px;
    margin-inline-end: 10px;
    color: var(--chakra-colors-navy-700);
}


.css-gllksg {
    display: inline-flex;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    border-radius: 45px;
    font-weight: var(--chakra-fontWeights-semibold);
    box-shadow: var(--chakra-shadows-none);
    transition: .25s all ease;
    box-sizing: border-box;
    height: 54px;
    min-width: var(--chakra-sizes-10);
    font-size: var(--chakra-fontSizes-sm);
    -webkit-padding-start: 16px;
    padding-inline-start: 16px;
    -webkit-padding-end: 16px;
    padding-inline-end: 16px;
    background: var(--color-chrome);



    color: var(--color-text);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left:20px;
    padding-right:20px;

    margin-inline-start: auto;
}





.css-1h62d89 {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: var(--chakra-radii-full);
    background: var(--chakra-colors-transparent);
    border: 1px solid;
    border-color: var(--chakra-colors-gray-200);
    margin-inline-end: 20px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
}

.css-9bephp {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.css-cyklgb {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-inline: auto;
    margin-bottom: auto;
}

.css-1ie6an7 {
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 1em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: var(--chakra-colors-brand-500);
}

.css-uuq0e0 {
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 1em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: var(--chakra-colors-gray-500);
    cursor: pointer;
    -webkit-margin-start: auto;
    margin-inline-start: auto;
}

.css-wybkwz {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 22px;
    border: 1px solid;
    border-color: var(--chakra-colors-gray-200);
    border-radius: 14px;
    width: 100%;
    z-index: 2;
}




/*RETUREND TEXT*/

.css-hboir5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0px;
}

.css-1oo0gu1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: var(--chakra-radii-full);
    background: linear-gradient(to bottom, #4a25e0, #7a5aff);
    -webkit-margin-end: 20px;
    margin-inline-end: 20px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
}

.css-1rgvbqi {
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 1em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: white;
}

/*Organization or Response*/

.css-adkx0o {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    width: 100%; /* Adjust the width as needed */
    max-width: 100%; /* Prevent the container from expanding beyond its parent */
    position: relative;

    overflow-wrap: break-word; /* Ensure text wraps within the container */
    word-wrap: break-word; /* Ensure text wraps within the container */
    word-break: break-all; /* Ensure text breaks at the end of container */
    box-shadow: rgba(112, 144, 176, 0.1) 14px 17px 40px 4px;
    color: var(--chakra-colors-navy-700);
    height: 450px;
    font-size: var(--chakra-fontSizes-sm);
    line-height: 24px;
    font-weight: 500;
    padding-inline-start: 22px !important;
    padding-inline-end: 22px !important;
    padding: 20px 20px 20px;
    border-radius: 14px;
    background: rgb(255, 255, 255);
    background-clip: border-box; /* Corrected background property */
    background: #fff;

}
.step{
  max-width: 70%; /* Ensure it takes full width */
  font-size: 5px;
}

  .step-tracker {
    max-width: 70%; /* Ensure it takes full width */
    margin-bottom: 16px; /* Adjust spacing below the step tracker */
  }

  .prompts-container {
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the container takes full width */
    padding-top: 10px;
    padding-bottom:5px;
    box-sizing: border-box; /* Include padding in the element's total width and height */
  }


  .css-1hngipw {
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-fast);
    transition-timing-function: var(--chakra-transition-easing-ease-out);
    cursor: pointer;
    text-decoration: none;
    outline: transparent solid 2px;
    outline-offset: 2px;
    color: var(--chakra-colors-navy-700);
    box-shadow: var(--chakra-shadows-none);
    background: inherit;
    border-radius: inherit;
    font-weight: var(--chakra-fontWeights-bold);
    font-size: 28px;
    padding-left: 10px;
    margin-bottom: 20px;
    flex: center;
    
}

.font-medium {
    white-space: normal; /* Ensure that text wraps normally */
    overflow-wrap: break-word; /* Ensure long words break and wrap */
    word-wrap: break-word; /* Ensure text wraps within the container */
    word-break: break-word; /* Ensure text breaks at the end of the container */
  }
  

/* src/components/Chatbot.css */
.message {
    margin-bottom: 12px;
    padding: 8px 12px;
    border-radius: 16px;
    white-space: pre-wrap; /* Preserve line breaks */
    max-width: 80%; /* Prevent messages from being too wide */
    text-align: center; /* Center the text */
  }
  

  
  .school-container {
    max-height: 500px; /* Adjust the height as needed */
    display: flex;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #fcfeff;
    padding-right: 20px; /* Add some padding for better spacing */
    padding-left: 20px; /* Add some padding for better spacing */
    padding-top: 10px; /* Add some padding for better spacing */
    padding-bottom: 10px;
    border-radius: 5px; /* Optional: add rounded corners */
    white-space: nowrap; /* Prevent wrapping */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Proper box shadow syntax */
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
    width: 500px;
  }



  .text-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    white-space: normal; /* Ensure that text wraps normally */
    overflow-wrap: break-word; /* Ensure long words break and wrap */
    word-wrap: break-word; /* Ensure text wraps within the container */
    word-break: break-word; /* Ensure text breaks at the end of the container */
    overflow-y: auto;
    max-height: 400px;
  }

 


 

 .school-item:first-child {
        border-top-width:0px;
}
      
.school-item:last-child {
        border-bottom: none;
}

.home-bot-message{
    text-align: left;
}




.schools-list{
  width: 100%;
    max-width: 100%;
    padding: 5px;
    box-sizing: border-box;
    align-items: center; 

}



  
@media (max-width: 912px) {

  html, body {
    overflow: visible;
  }
  


  .row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start; /* Adjust to start to ensure columns stack properly */
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  .row {
    display: flex;
    flex-direction: column-reverse; /* Stack child containers vertically */
    justify-content: flex-start; /* Ensure content aligns at the top initially */
    width: 100%; /* Full width */
    height: 100%; /* Full height of the screen */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  /* Top container */
  .column-right {
    flex-grow: 1; /* Fill the remaining space below .column-left */
    overflow-y: auto; /* Enable scrolling if content overflows */
    align-self: stretch; /* Ensure it stretches to full width */
    padding-top: 15px;
      box-sizing: border-box;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Bottom container */
  .column-left {
    margin-top: 10px;
    flex-shrink: 0;
    height: auto;
    box-sizing: border-box;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

  
  .css-16ld5u0 {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    min-height: 0px;
  }

  .css-1k6m9o {
    padding-top: 0px;
    box-sizing: border-box;
  }

  .css-1799jpi {
    width: 100%;
    max-width: none;
    box-sizing: border-box;
    padding-right: 0;
  }

 

  .css-1pgcnou {
    width: 100%;
    padding-top: 0px;
    box-sizing: border-box;
    min-height: 0px;
    padding-right: 0px;
  }

  .css-gllksg {
    height: 30px;
    min-width: 100%;
    font-size: var(--chakra-fontSizes-xs);
    padding: 10px;
    margin-inline-start: 0;
    box-sizing: border-box;
  }

  .css-1h62d89 {
    margin-inline-end: 10px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    box-sizing: border-box;
  }

  .css-9bephp {
    margin-bottom: 5px;
    box-sizing: border-box;
  }

  .css-cyklgb {
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .css-adkx0o {
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    overflow-y: auto; /* Enable vertical scrolling */
    box-sizing: border-box;
    padding-inline-start: 0px;
    box-shadow: 0 0 40px 4px rgba(112, 144, 176, .2);
    font-weight: 400;

  }

  .step-tracker {
    max-width: 100%;
  }

  .step {
    max-width: 50%; /* Keep the step responsive */
  }

  .prompts-container {
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the container takes full width */
    padding-top: 5px;
    padding-bottom:0px;
    box-sizing: border-box; /* Include padding in the element's total width and height */
  }

  .css-1hngipw {
    font-size: 24px;
    text-align: center;
    box-sizing: border-box;
    justify-content: center;
    padding-left: 15px;
  }

  .font-medium {
    font-size: 14px;
    box-sizing: border-box;
  }

  .text-container p {
    font-size: 2px !important; /* Adjust the font size for mobile screens */
    box-sizing: border-box;
  }

  .message {
    max-width: 90%;
    padding: 5px 10px;
    box-sizing: border-box;
  }

  .college-search {
    width: 100%;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
    align-items: flex-start; 
  }


  .text-container {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0px;
    line-height: 1.6;
    padding-inline-end: 0px;
    font-weight: 100px;
    padding-inline-start: 0px;

  }


  .css-6n9yju {
    flex-direction: row;
    align-items: stretch;
    gap: 0px;
    margin-top: 5px;
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .chakra-input.css-1pgcnou {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 10px;
    box-sizing: border-box;

  
  }

  .chakra-button.css-gllksg {
    width: 10px; /* Set a fixed width */
  }

  .chakra-button.css-gllksg,
  .chakra-button.css-reset {
    height: 40px;
    min-width: 10%;
    font-size: 12px;
    padding: 0 12px;
    margin-bottom: 10px;
    border-radius: 10px;
  
    box-sizing: border-box;
  }


  .colum-left{
    padding: 0px;
    margin: 0px;
  }
  


  .school-container {
    max-height: 300px; /* Limit the container's maximum height */
    display: flex;
    flex-direction: column; /* Stack items vertically if needed */
    justify-content: flex-start; /* Align items at the top */
    align-items: flex-start; /* Align items to the left */
    background: #fcfeff;
    padding: 10px; /* Add some padding for better spacing */
    border-radius: 5px; /* Optional: Add rounded corners */
    white-space: normal; /* Allow wrapping inside if needed */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure it takes full width */
    box-sizing: border-box; /* Ensure padding is included in width/height */
  }
  


}