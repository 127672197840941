.no-outline {
  border: none;
  outline: none;
  box-shadow: none;
}

.school-details-container {
  display: flex;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 1445px; /* Restrict the container's maximum width */
  width: 100%; /* Make it responsive */
  margin: 20px auto; /* Center it horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box; /* Include padding in width calculations */
}

.school-details-content {
  flex: 3; 
  margin-right: 20px; 
  width: 980px;
  min-width: 980px;
  max-width: 980px;
}

.screen-container {
    flex: 1;
    flex-direction: column; 
    gap: 20px; 
    width: 35%; 
    height: 100%; 
  
}

.school-details {
  flex: 1;
}



h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  margin-bottom: 5px;
}

.screen-container {
  display: flex;
  flex-direction: column; 
  gap: 20px; 
  width: 35%; 
}

.chat-container-wrapper {
  flex: 1;
  margin-top: 20px;
}

.chat-container2-wrapper {
  flex: 1;
  height: auto;
}

.chat-container,
.chat-container2 {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex: 1; /* Allow messages to take up remaining space */
  max-height: 500px;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: rgba(112, 144, 176, 0.1) 14px 17px 40px 4px;
  font-size: 15px;
}

.chat-box {
  max-height: 500px; /* Choose any value you like */
  overflow-y: auto;  /* Enables vertical scrolling if content is too tall */
}

.message {
  margin-bottom: 10px;
}

.message.user {
  text-align: right;
  color: blue;
}

.message.bot {
  text-align: left;
  margin-bottom: 12px;
  padding: 8px 12px;
  border-radius: 16px;
  white-space: pre-wrap; /* Preserve line breaks */
  max-width: 95%; /* Prevent messages from being too wide */
}
.tab-button[data-state='active'] {
  color: blue;
  background-color: green;
}

.tab-button {
  color: rgb(183, 183, 183);
  background-color: rgb(106, 19, 47);
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.submit-button,
.reset-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button {
  background: linear-gradient(to top, #4a25e0, #7a5aff);
  color: white;
}



.submit-button:hover,
.reset-button:hover {
  opacity: 0.9;
}

.badge {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 9999px;
  background-color: #f3f4f6;
  color: #111827; 
  font-size: 0.875rem;
  font-weight: 500;
}

.badge-pill {
  margin-right: 0.5rem;
}

.custom-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Equivalent to gap-2 in Tailwind CSS */
}


@media (max-width: 768px) {
  .school-details-container {
    display: flex; /* Ensure it behaves as a flex container */
    flex-direction: column; /* Stack child elements vertically */
    width: 100%; /* Take the full width of the parent container */
    height: auto; /* Adjust height based on content */
    max-width: 100%; /* Prevent any width constraints */
    padding-left: 0; /* Remove left padding */
    padding-right: 0; /* Remove right padding */
    margin: 0; /* Remove any default margins */
    box-sizing: border-box; /* Include padding and borders in size calculation */
  }
  .school-details {
    width: 100%; /* Slightly wider for mobile screens */
    max-width: none; /* Remove max-width for smaller screens */
  }
  

  .school-details-content {
    order: -1; /* Move school-details-content to the top */
    width: 100%;
    margin-right: 0;
    flex: none;
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;

  }


  .p-6.pt-0 {
    padding: 0; /* Remove all padding on mobile */
  }
  

  .screen-container {
    order: 0; /* Ensure screen-container comes after school-details-content */
    gap: 10px;
    width: 100%;
  }

  .chat-container-wrapper {
    order: 1; /* Ensure chat-container-wrapper comes after screen-container */
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding:0px;

  }

  .chat-container,
  .chat-container2 {
    padding: 10px;
    box-shadow: none; /* Remove box shadow on smaller screens */
  }

  .messages {
    max-height: 300px; /* Reduce the height for smaller screens */
    width: 100%;
  }

  .custom-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap as needed */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 10px; /* Optional: add some padding */
    justify-content: left;
  }
  
  .custom-flex-wrap::-webkit-scrollbar {
    height: 8px; /* Adjust the height of the scrollbar */
  }
  
  .custom-flex-wrap::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* Customize the scrollbar thumb color */
    border-radius: 10px;
  }
  
  .custom-flex-wrap::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Customize the scrollbar track color */
  }
  
  .badge-container {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 0; /* Optional: add padding for better spacing */
  }
  
  .badge-pill {
    display: inline-block;
    padding: 0.5em 1em;
    border-radius: 9999px;
    background-color: #f3f4f6;
    color: #111827;
    font-size: 11px;
    font-weight: 500;
    white-space: nowrap; /* Prevent badges from wrapping to the next line */
  }
}
