

.add-scholarship-button {
    background: var(--color-chrome);
    border: none;
    color:var(--color-text);;
    padding: 7px 12px;
    font-size: 11px;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
  }
  
  .add-scholarship-button:hover {
    background: var(--color-chrome);
  }
  