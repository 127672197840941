.step-tracker {
    display: flex;
    justify-content: center; 
    gap: 10px;
    margin-bottom: 30px;

  }
  
  .step {
    padding: 8px 16px; 
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    font-size: 12px; 
    color: var(--color-text);
  }
  
  .step.active {
    background: var(--color-chrome);
    color: var(--color-text);
    border-color: #fff;
  }
  
  .step:hover {
    opacity: 0.8;
  }
  

  @media (max-width: 599px) {
    .step-tracker {
      justify-content: center; 
      gap: 5px;
      margin-bottom: 10px;
      overflow-x: auto; 
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

    .step {
        padding: 2px 9px; /* Adjust padding for mobile */
        font-size: 11px; /* Adjust font size for mobile */
    }

 

    .step:hover {
        opacity: 0.8;
    }
}