@tailwind base;
@tailwind components;
@tailwind utilities;



.schools-list {
  list-style-type: none;
  padding: 0;
}



.school-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.school-container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fcfeff;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  width: 500px;
  min-height: 70px ; 
  max-height: 500px;
}

.school-container2:hover {
  background-color: rgb(245, 245, 245); /* background color on hover */
}



.column-left {
  flex: 1;
}

.column-left2{
  max-width: 60%;
  flex: 1;
}

.column-right2 {
  display: flex;
  align-items: center;

  
}


.column-right {
  flex: 1;
  text-align: right;
  white-space: nowrap; /* Prevent wrapping */
}

.column-holder{

}

.my-price {
  color: black; /* Example color */
  font-weight: bold; /* Optional: make the text bold */
}

.custom-star {
  background-color: #DABFFF;
  color: #4B0082;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 4px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.school-link {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none; 
  color: #000; /* Text color */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
  white-space: normal; 
  width: auto; 
  max-width: 100%; /* Ensure it does not exceed the container */
}



.css-y5xvhi {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  padding-inline-start: var(--chakra-space-1);
  padding-inline-end: var(--chakra-space-1);
  text-transform: uppercase;
  border-radius: 10px;
  box-shadow: var(--badge-shadow);
  line-height: 100%;
  padding: 7px 12px;
  --badge-bg: white;
  --badge-color: white;
  color: black;
  font-size: var(--chakra-fontSizes-sm);
  font-weight: 600;
}



.school-checkbox {
  margin-top: 8px; /* Space between checkbox and label */

  cursor: pointer; 
  /* Change cursor to pointer when hovering over the checkbox */
  
}

.ThreeDotsMenu{
  padding-left: 30px;
}

.lock{
    flex: 1 1;
}


@media (max-width: 912px) {
  .school-container2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcfeff;
    padding: 0px;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
    width: 100%;
    min-height: 50px ; 
    max-height: 500px;
  }
  .school-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .custom-star {
    font-size: 7px; /* Smaller font size for mobile */
    background-color: #DABFFF;
    color: #4B0082;
    font-weight: 500;
    padding: 2px 3px;
    border-radius: 4px;
    margin: 0px;
    
  }


  .school-link {
    font-size: 14px; /* Adjust font size for .school-link on mobile */
  }

  .css-y5xvhi {
    font-size: 14px; /* Adjust font size for .css-y5xvhi on mobile */
  }


}
